import React, { type SyntheticEvent } from 'react'
import Select from '@mui/joy/Select'
import Option from '@mui/joy/Option'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { useTranslation } from 'react-i18next'

export default function TimePicker({
    timeType,
    handleSelect,
    value,
    timeHighlight,
    desktop,
} : {
    timeType: 'start' | 'end',
    handleSelect: (event: SyntheticEvent | null, newValue: string | null, name : string) => void,
    value: string | null,
    timeHighlight: boolean,
    desktop?: boolean,
}) {

    const { t } = useTranslation()

    return (
        <FormControl sx={{width: '100%'}}>
            {desktop ?
                <FormLabel
                    id={timeType+'Time-label'}
                    sx={{
                        mb: 2,
                        fontWeight: 'md',
                        fontSize: 'lg',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    {t('search.'+timeType)}
                </FormLabel>
                :
                <FormLabel id={timeType+'Time-label'} htmlFor={timeType+'Time-button'}>
                    {t('search.'+timeType)}
                </FormLabel>
            }
            <Select
                value={value}
                onChange={(e, newValue) => handleSelect(e, newValue, timeType+'Time')}
                slotProps={{
                    root: {
                        sx: {
                            bgcolor: timeHighlight ? 'secondary.plainActiveBg' : 'white',
                            transition: '250ms background-color',
                        },
                    },
                    button: {
                        id: timeType+'Time-button',
                        'aria-labelledby': `${timeType}Time-label ${timeType}Time-button`,
                        sx: {
                            // legacy application stylesheet override
                            fontWeight: 'inherit',
                            '&:focus': {
                                boxShadow: 'none',
                                outline: 'none',
                            },
                            '&:hover': {
                                color: 'inherit',
                            },
                        }
                    },
                }}
            >
                <Option value="00:01">00:01</Option>
                {Array.from({length: 23}, (_, i) => {
                    const hour = String(i + 1).padStart(2, '0')
                    const time = `${hour}:00`
                    return <Option key={time} value={time}>{time}</Option>
                })}
            </Select>
        </FormControl>
    )
}